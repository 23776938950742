import { fileToText } from "/src/js/utils/fileToText"

const icons = document.querySelectorAll(".s-icon")
const svgs = document.querySelectorAll(".s-svg")

async function loadIcons() {
    for (const icon of icons) {
        const attr = icon.getAttribute("icon")
        const data = await fileToText(`/imgs/icons/${attr}.svg`)
        icon.innerHTML += data
    }
}
async function loadSvgs() {
    for (const svg of svgs) {
        const attr = svg.getAttribute("svg")
        /* const data = await fileToText(`/svgs/${attr}.svg`)
        svg.innerHTML += data */
        svg.style.backgroundImage = `url(/imgs/svgs/${attr}.svg)`
    }
}
loadIcons()
loadSvgs()
